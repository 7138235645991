"use strict";
//Models
import FiltersModel from '../_filters/model.js';

// update the query string based on the selected filters
const updateQueryString = FiltersModel.updateQueryString;

export default class Home {

    // update home page map
    static updateHomePageMap(){

        const url = $('#homepage-map').data('route');

        //update the current query in the form
        const $form = $('#search-page-filters');
        const queryString = updateQueryString($form);
        $form.data('query', queryString);

        const $map = $('#france-map');
        const $loader = $('#homepage-map .loader');

        // Show the loader
        $loader.show();
        $map.addClass('blur-sm');
        

        $.ajax({
            headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
            url: url,
            type: 'POST',
            data: queryString,
            dataType: 'json',
            context: this,
            success: function (response) {
                // Iterate through the JSON data
                $.each(response.areas, function(_, area) {
                    
                    const adCountElement = $('[data-region-name="' + area.name + '"]')
                    .siblings('.ads-count').find('text');
                    // Find the element with the corresponding region name and set its ads count
                    adCountElement.text(area.total);
                });

                // Hide the loader
                $loader.hide();
                $map.removeClass('blur-sm');
            },
            error: function() {
                console.error('Error fetching data');  
            }
        });
    }   

}